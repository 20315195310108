import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { API_ENDPOINT } from '../constants';
import axios from 'axios';

const aspectRatio = 420 / 525;

class ImageCrop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            crop: { unit: 'px', width: 252, height: 315, aspect: aspectRatio },
            currentIndex: 0,
            showModal: false,
            savedCrops: {},
            imageDimensions: { width: 0, height: 0 },
        };
    }

    onCropChange = (crop) => {
        if (crop.width && crop.height) {
            const newWidth = crop.height * aspectRatio;
            crop.width = newWidth;
        } else if (crop.width) {
            crop.height = crop.width / aspectRatio;
        } else if (crop.height) {
            crop.width = crop.height * aspectRatio;
        }

        this.setState({ crop });
    };

    onImageLoad = (event) => {
        const { width, height } = event.target;
        this.setState({ imageDimensions: { width, height } });
    };

    getCropPositions = (crop) => {
        const { width, height } = this.state.imageDimensions;
        const top = (crop.y / height) * 100;
        const bottom = ((crop.y + crop.height) / height) * 100;
        const left = (crop.x / width) * 100;
        const right = ((crop.x + crop.width) / width) * 100;

        return { top, bottom, left, right };
    };

    saveCrop = () => {
        const { crop, currentIndex } = this.state;
        const positions = this.getCropPositions(crop);

        const cropData = {
            positions: positions,
            ...this.props.images[currentIndex],
        };

        this.setState((prevState) => ({
            savedCrops: {
                ...prevState.savedCrops,
                [currentIndex]: cropData,
            },
        }));
    };

    saveAllCrops = () => {
        const allCropsData = Object.keys(this.state.savedCrops).map(index => {
            const crop = { ...this.state.savedCrops[index] };
            crop.positions = {
                top: crop.positions.top > 100 ? 100 : crop.positions.top,
                bottom: crop.positions.bottom > 100 ? 100 : crop.positions.bottom,
                left: crop.positions.left > 100 ? 100 : crop.positions.left,
                right: crop.positions.right > 100 ? 100 : crop.positions.right,
            };

            return crop;
        });
        axios
            .post(
                API_ENDPOINT + '/crop',
                allCropsData,
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization:
                            typeof localStorage !== 'undefined'
                                ? `Bearer ${localStorage.getItem('authToken')}`
                                : null,
                    },
                }
            )
            .then((response) => {
                if (response.status !== 200) {
                    this.setState({ showModal: false });
                    this.setState({ error: response.data, _loading: null });
                } else {
                    this.setState({
                        _loading: null,
                        imageSet: true,
                        image: response.data,
                    });
                }
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status !== 200) {
                        this.setState({ error: error.response.data, _loading: null });
                    }
                }
            });
    };

    toggleModal = () => {
        this.setState((prevState) => ({ showModal: !prevState.showModal, cropMessage: '' }));
    };

    goToNextImage = () => {
        this.setState((prevState) => {
            const nextIndex = (prevState.currentIndex + 1) % this.props.images.length;
            const nextCrop = prevState.savedCrops[nextIndex]?.positions
                ? this.getCropFromPositions(prevState.savedCrops[nextIndex].positions)
                : { unit: '%', width: 50 * aspectRatio, height: 50, aspect: aspectRatio }; // Set initial crop

            return {
                currentIndex: nextIndex,
                crop: nextCrop,
            };
        });
    };

    goToPreviousImage = () => {
        this.setState((prevState) => {
            const prevIndex =
                (prevState.currentIndex - 1 + this.props.images.length) % this.props.images.length;
            const prevCrop = prevState.savedCrops[prevIndex]?.positions
                ? this.getCropFromPositions(prevState.savedCrops[prevIndex].positions)
                : { unit: '%', width: 50 * aspectRatio, height: 50, aspect: aspectRatio }; // Set initial crop

            return {
                currentIndex: prevIndex,
                crop: prevCrop,
            };
        });
    };

    getCropFromPositions = (positions) => {
        return {
            unit: '%',
            width: positions.right - positions.left,
            height: positions.bottom - positions.top,
            x: positions.left,
            y: positions.top,
            aspect: 1 / 1,
        };
    };

    handleCropComplete = (crop) => {
        this.saveCrop();
    };

    render() {
        const { crop, showModal, currentIndex, savedCrops } = this.state;
        const { images } = this.props;
        console.log(images);

        return (
            <>
                <Button className='form-button' onClick={this.toggleModal}>Crop</Button>

                <Modal className='crop-modal' isOpen={showModal} toggle={this.toggleModal}>
                    <ModalBody>
                        {images && images.length > 0 ? (
                            <>
                                <ReactCrop
                                    className='crop-body'
                                    crop={crop}
                                    onChange={this.onCropChange}
                                    onComplete={this.handleCropComplete}
                                >
                                    <img
                                        src={API_ENDPOINT + images[currentIndex]?.file}
                                        alt="Crop"
                                        onLoad={this.onImageLoad}
                                    />
                                </ReactCrop>
                                {savedCrops[currentIndex] && (
                                    <div className="saved-crop-message">Crop saved!</div>
                                )}
                            </>
                        ) : (
                            <div className="message">No images available</div>
                        )}
                    </ModalBody>
                    <ModalFooter className='modal-footer-crop'>
                        <div>
                            <Button color="secondary" onClick={this.goToPreviousImage}>Previous</Button>
                            <Button color="secondary" onClick={this.goToNextImage}>Next</Button>
                            <Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
                        </div>
                        <div>
                            <Button color='primary' className='save-cropBtn' onClick={this.saveAllCrops}>Save All Images</Button>

                        </div>
                    </ModalFooter>
                </Modal>
            </>
        );
    }
}

export default ImageCrop;
