import React from 'react';
import Page from '../../containers/page';
import FormBuilder from '../../form-builder';
import RouteComponent from '../../helpers/route';
import ListBuilder from '../../list-builder';
import Isvg from 'react-inlinesvg';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import editIcon from '../../assets/edit.svg';
import garbageIcon from '../../assets/garbage.svg';
import DeleteModal from '../../components/deleteModal';
import { required } from '../../form-builder/validation';
import FileField from '../../form-builder/fields/file';
import moment from 'moment';
import { API_ENDPOINT } from '../../constants';

class Home extends RouteComponent {
    constructor(props) {
        super(props);
        this.state = {
            articlesList: true,
            articleForm: false,
            total: 20,
            items: [

            ],
            articleTypes: [],
            groups: [],
            subgroups: [],
            manufacturers: [],
            atc: [],
            shapes: [],
            modes: [],
            attributes: [],
            categories: [],
            modal: false,
        }
    }



    componentDidMount() {
        this._onLoad();

        this._api('categories', {}, (data) => {
            this.setState({ categories: data.categories })
        })

    }

    toggle = () => {
        this.setState({ modal: !this.state.modal })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props[0].location.search !== prevProps[0].location.search) {
            this._onLoad();
        }
    }
    export = () => {
        fetch(API_ENDPOINT + '/data/products/export', {
            method: 'POST', credentials: 'include',
            headers: {
                'content-type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },

            body: JSON.stringify({
                _hidden: true
            })
        }).then(res => res.blob()).then(data => { if (data) window.open(URL.createObjectURL(data)) })

    }
    render() {
        let params = this._getParams();

        return (
            <div>
                <div className="section-title title-options">
                    <h1>{'All products'}</h1>
                    {this.props[0].location.search.indexOf('_hidden') !== -1 ?
                        <div className="ff-export">

                            <button onClick={this.export} className="button">Export</button>
                        </div>

                        :
                        <FileField label={'Import sale list .xlsx'} endpoint={'/data/products/sale-import'} value={this.state.saleFile} onChange={(val) => this.setState({ saleFile: val })}></FileField>
                    }
                </div>
                {this.state.articleForm ?
                    <div className="table-tabs">
                        <button onClick={() => this.setState({ articleForm: false }, () => {
                            this.updateParams('_newProduct', null, true);

                        })}>
                            {'Products list'}
                        </button>
                        <button onClick={() => {
                            this.setState({
                                articleForm: false,
                                articlesList: true
                            })
                            this.updateParams('_newProduct', true, true);

                        }}>
                            {'New products'}
                        </button>
                        <button onClick={() => {
                            this.setState({
                                articleForm: false,
                                articlesList: true
                            })
                            this.updateParams('_hidden', true, true);

                        }}>
                            {'Hidden products'}
                        </button>
                        <button className="active" onClick={() => this.setState({ articleForm: {} })}>
                            {this.state.articleForm && this.state.articleForm._id ? 'Edit product' : 'Add new product'}
                        </button>
                    </div>
                    : null}

                {
                    this.state.articleForm ?

                        <div>
                            <div className="side-margin">
                                <FormBuilder
                                    registerSubmitAction={(submit) => this.submit = submit}
                                    classNameForm={"add-form-container"}
                                    fieldTracker={(name, value) => {
                                        if (name === 'isHidden') {
                                            this.setState({
                                                isHidden: value
                                            })
                                        }
                                    }}
                                    fieldsToTrack={['isHidden']}
                                    // headerForm={"Artikal"}
                                    //                                  addButtonText={this.state.articleForm._id ? 'Edit product' :  'Add new'}
                                    lang={this.props.lang}
                                    onSubmit={(data) => {

                                        if (this.state.articleForm && this.state.articleForm._id) {
                                            this.toggle();

                                            this._api('api4', { data: data }, () => {
                                                this.setState({ articleForm: undefined }, () => {
                                                    this._onLoad(); this.toggle();
                                                })

                                            });
                                        } else {
                                            this._api('api2', { data: data }, () => {
                                                this.setState({ articleForm: {} }, () => { this._onLoad() })
                                            });
                                        }
                                    }}
                                    initialValues={this.state.articleForm && this.state.articleForm._id ? this.state.articleForm : {}}
                                    fields={[
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'col',
                                                    width: { lg: 8, sm: 8, xs: 8 },
                                                    children: [
                                                        {
                                                            type: 'block',
                                                            header: 'Product info',
                                                            style: { paddingBottom: 33 },
                                                            children: [
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'sku',
                                                                            next: 'name',
                                                                            label: 'SKU',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                /*required('Šifra mora biti unesena!'),
                                                                                minLength(2, 'Mora minimalno 2 karaktera'),
                                                                                maxLength(2, 'Mora maksimalno 2 karaktera'),
                                                                                number('Mora biti broj'),*/
                                                                                required('SKU is required!'),
                                                                                async (value) => {
                                                                                    try {
                                                                                        if (value) {
                                                                                            let res = await this._apiAsync('validate', { value: value, id: this.state.articleForm._id ? this.state.articleForm._id : null });
                                                                                            if (res.status === 400) {
                                                                                                return 'Error'
                                                                                            }
                                                                                            return undefined;
                                                                                        }

                                                                                    } catch (e) {
                                                                                        return undefined;
                                                                                    }
                                                                                },
                                                                            ]
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'barcode',
                                                                            next: 'name',
                                                                            label: 'Barcode',
                                                                        },

                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'brand',
                                                                            multilang: false,
                                                                            next: 'unit',
                                                                            label: 'Brand',
                                                                        },
                                                                    ]
                                                                },
                                                                // {
                                                                //     type: 'col',
                                                                //     width: { lg: 2, sm: 3, xs: 3 },
                                                                //     children: [
                                                                //         {
                                                                //             type: 'switch',
                                                                //             name: 'active',
                                                                //             next: 'featured',
                                                                //             label: 'Active',
                                                                //         }
                                                                //     ]
                                                                // },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'isHidden',
                                                                            next: 'featured',
                                                                            label: 'Hidden',
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'isHiddenForPublic',
                                                                            next: 'featured',
                                                                            label: 'Hidden for public (not logged in)',
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                                    children: [
                                                                        {
                                                                            type: 'switch',
                                                                            name: 'isBestSeller',
                                                                            next: 'featured',
                                                                            label: 'BEST SELLER',
                                                                        }
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        this.state.isHidden ?
                                                                            {
                                                                                type: 'text',
                                                                                name: 'note',
                                                                                multilang: false,
                                                                                next: 'unit',
                                                                                label: 'Note',

                                                                            } : null
                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'name',
                                                                            multilang: true,
                                                                            next: 'unit',
                                                                            label: 'Name',
                                                                            asyncValidation: true,
                                                                            validate: [
                                                                                // ne smije zarez
                                                                                required('Name is required!'),
                                                                                // (value) => { if (!value || value === '') return "Šifra mora biti unesena!" }
                                                                            ]
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'text',
                                                                            name: 'alias',
                                                                            multilang: true,
                                                                            disabled: true,
                                                                            next: 'unit',
                                                                            label: 'Alias',
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'date',
                                                                            name: 'tsCreated',
                                                                            next: 'unit',
                                                                            label: 'Published',
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            categories: this.state.categories,
                                                                            type: 'image-select',
                                                                            name: 'thumbnailExposed',
                                                                            next: 'unit',
                                                                            label: 'Thumbnail exposed',
                                                                            values: this.state.articleForm.images
                                                                        },
                                                                    ]
                                                                },

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            categories: this.state.categories,
                                                                            type: 'image-select',
                                                                            name: 'thumbnailMood',
                                                                            next: 'unit',
                                                                            label: 'Thumbnail mood',
                                                                            values: this.state.articleForm.images
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            categories: this.state.categories,
                                                                            type: 'image-select',
                                                                            name: 'thumbnailMood2',
                                                                            next: 'unit',
                                                                            label: 'Thumbnail mood 2',
                                                                            values: this.state.articleForm.images
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            categories: this.state.categories,
                                                                            type: 'image-select',
                                                                            name: 'thumbnailMood3',
                                                                            next: 'unit',
                                                                            label: 'Thumbnail mood 3',
                                                                            values: this.state.articleForm.images
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            categories: this.state.categories,
                                                                            type: 'image-select',
                                                                            name: 'thumbnailMood4',
                                                                            next: 'unit',
                                                                            label: 'Thumbnail mood 4',
                                                                            values: this.state.articleForm.images
                                                                        },
                                                                    ]
                                                                },



                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'product-select',
                                                                            name: 'groupProducts',
                                                                            next: 'unit',
                                                                            label: 'Group products',
                                                                        },
                                                                    ]
                                                                },
                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 6, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            type: 'product-select',
                                                                            name: 'productVariations',
                                                                            next: 'unit',
                                                                            label: 'Product variations',
                                                                        },
                                                                    ]
                                                                },


                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [
                                                                        {
                                                                            categories: this.state.categories,
                                                                            type: 'categories',
                                                                            name: 'categories',
                                                                            next: 'unit',
                                                                            label: 'Categories',
                                                                        },
                                                                    ]
                                                                },

                                                            ]
                                                        },

                                                        {
                                                            type: 'block',
                                                            header: 'Product details',
                                                            style: { marginTop: 15, },
                                                            children: [

                                                                {
                                                                    type: 'col',
                                                                    width: { lg: 12, sm: 12, xs: 12 },
                                                                    children: [

                                                                        {
                                                                            type: 'html',
                                                                            name: 'description',
                                                                            multilang: true,
                                                                            next: 'stock',
                                                                            label: 'Description',

                                                                        }
                                                                    ]
                                                                }

                                                            ]
                                                        },


                                                    ]
                                                },
                                                {
                                                    type: 'col',
                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                    children: [

                                                        {
                                                            type: 'block',
                                                            header: 'Pictures',
                                                            style: { marginLeft: 0, marginTop: 0 },
                                                            children: [
                                                                {
                                                                    type: 'media-gallery',
                                                                    name: 'images',
                                                                    next: 'stock',
                                                                    endpoint: '/data/products/assets/upload',
                                                                    label: 'Images',
                                                                    sku: this.state.articleForm ? this.state.articleForm.sku : ''

                                                                },
                                                                {
                                                                    type: 'button',
                                                                    width: { lg: 12, sm: 6, xs: 6 },
                                                                    action: 'submit',
                                                                    children: 'Save',
                                                                    className: 'form-button',
                                                                },


                                                            ]
                                                        },


                                                        this.state.articleForm && (this.state.articleForm.brand === 'Vind' || this.state.articleForm.brand === 'Lusting') ?
                                                            {
                                                                type: 'block',
                                                                header: 'QR Code | vindcollection.se',
                                                                style: { marginLeft: 0, marginTop: 20 },
                                                                children: [
                                                                    {
                                                                        type: 'qr',
                                                                        sku: this.state.articleForm ? this.state.articleForm.sku : 'qrcode',
                                                                        name: 'alias',
                                                                        next: 'stock',

                                                                    },

                                                                ]
                                                            } :
                                                            this.state.articleForm && (this.state.articleForm.brand === 'Venture Home') ?


                                                                {
                                                                    type: 'block',
                                                                    header: 'QR Code | venturehome.se',
                                                                    style: { marginLeft: 0, marginTop: 20 },
                                                                    children: [
                                                                        {
                                                                            type: 'qr2',
                                                                            sku: this.state.articleForm ? this.state.articleForm.sku : 'qrcode',
                                                                            name: 'alias',
                                                                            next: 'stock',

                                                                        },

                                                                    ]
                                                                } :
                                                                {
                                                                    type: 'block',
                                                                    header: 'QR Code | venturedesign.se',
                                                                    style: { marginLeft: 0, marginTop: 20 },
                                                                    children: [
                                                                        {
                                                                            type: 'qr1',
                                                                            sku: this.state.articleForm ? this.state.articleForm.sku : 'qrcode',
                                                                            name: 'alias',
                                                                            next: 'stock',

                                                                        },

                                                                    ]
                                                                },
                                                        {
                                                            type: 'block',
                                                            header: 'Crop',
                                                            style: { marginLeft: 0, marginTop: 20 },
                                                            children: [
                                                                {
                                                                    type: 'react-crop',
                                                                    width: { lg: 12, sm: 6, xs: 6 },
                                                                    // action: this.state.,
                                                                    children: 'Crop',
                                                                    className: 'form-button',
                                                                },
                                                            ]
                                                        }


                                                    ]
                                                }, {
                                                    type: 'col',
                                                    width: { lg: 4, sm: 4, xs: 4 },
                                                    children: [


                                                    ]
                                                },
                                                // {
                                                //     type: 'col',
                                                //     width: { lg: 8, sm: 8, xs: 8 },
                                                //     children: [
                                                //         {
                                                //             type: 'block',
                                                //             header: 'Attributes',
                                                //             style: { paddingBottom: 33, marginTop: 20 },
                                                //             children: this.state.attributes.map((attribute) => {

                                                //                 return {
                                                //                     type: 'col',
                                                //                     width: { lg: 4, sm: 4, xs: 4 },
                                                //                     children: [
                                                //                         {
                                                //                             type: 'text',
                                                //                             name: `productAttributes.${attribute.key}`,
                                                //                             next: 'unit',
                                                //                             label: attribute.name[this.props.lang],
                                                //                         },


                                                //                     ]
                                                //                 }
                                                //             }


                                                //             )

                                                //         },
                                                //     ]
                                                // }



                                                // ...this.state.attributes.map((group) => {
                                                //     return {
                                                //         type: 'col',
                                                //         width: { lg: 8, sm: 8, xs: 8 },
                                                //         children: [
                                                //             {
                                                //                 type: 'block',
                                                //                 header: group.name[this.props.lang],
                                                //                 style: { paddingBottom: 33, marginTop: 20 },
                                                //                 children: group.attributes.map((attribute) => {

                                                //                         if (attribute.type === 'TextAttribute')
                                                //                             return {
                                                //                                 type: 'col',
                                                //                                 width: { lg: 4, sm: 4, xs: 4 },
                                                //                                 children: [
                                                //                                     {
                                                //                                         type: 'text',
                                                //                                         name: `attributes.${attribute.name}`,
                                                //                                         next: 'unit',
                                                //                                         label: attribute.label[this.props.lang],
                                                //                                     },

                                                //                                 ]
                                                //                             }
                                                //                         else if (attribute.type === 'IntAttribute')
                                                //                             return {
                                                //                                 type: 'col',
                                                //                                 width: { lg: 4, sm: 4, xs: 4 },
                                                //                                 children: [
                                                //                                     {
                                                //                                         type: 'text',
                                                //                                         name: `attributes.${attribute.name}`,
                                                //                                         next: 'unit',
                                                //                                         label: attribute.label[this.props.lang],
                                                //                                     },

                                                //                                 ]
                                                //                             }
                                                //                         else if (attribute.type === 'BooleanAttribute')
                                                //                             return {
                                                //                                 type: 'col',
                                                //                                 width: { lg: 12, sm: 12, xs: 12 },
                                                //                                 children: [
                                                //                                     {
                                                //                                         type: 'switch',
                                                //                                         name: `attributes.${attribute.name}`,
                                                //                                         next: 'unit',
                                                //                                         label: attribute.label[this.props.lang],
                                                //                                     },

                                                //                                 ]
                                                //                             }

                                                //                         else if (attribute.type === 'DecimalAttribute')
                                                //                             return {
                                                //                                 type: 'col',
                                                //                                 width: { lg: 4, sm: 4, xs: 4 },
                                                //                                 children: [
                                                //                                     {
                                                //                                         type: 'text',
                                                //                                         name: `attributes.${attribute.name}`,
                                                //                                         next: 'unit',
                                                //                                         label: attribute.label[this.props.lang],
                                                //                                     },

                                                //                                 ]
                                                //                             }


                                                //                         else if (attribute.type === 'MultilineAttribute')
                                                //                             return {
                                                //                                 type: 'col',
                                                //                                 width: { lg: 4, sm: 4, xs: 4 },
                                                //                                 children: [
                                                //                                     {
                                                //                                         type: 'textarea',
                                                //                                         name: `attributes.${attribute.name}`,
                                                //                                         next: 'unit',
                                                //                                         label: attribute.label[this.props.lang],
                                                //                                     },

                                                //                                 ]
                                                //                             }
                                                //                         else if (attribute.type === 'HtmlAttribute')
                                                //                             return {
                                                //                                 type: 'col',
                                                //                                 width: { lg: 4, sm: 4, xs: 4 },
                                                //                                 children: [
                                                //                                     {
                                                //                                         type: 'html',
                                                //                                         name: `attributes.${attribute.name}`,
                                                //                                         next: 'unit',
                                                //                                         label: attribute.label[this.props.lang],
                                                //                                     },

                                                //                                 ]
                                                //                             }
                                                //                         else if (attribute.type === 'DropdownAttribute')
                                                //                             return {
                                                //                                 type: 'col',
                                                //                                 width: { lg: 4, sm: 4, xs: 4 },
                                                //                                 children: [
                                                //                                     {
                                                //                                         type: 'select',
                                                //                                         name: `attributes.${attribute.name}`,
                                                //                                         next: 'unit',
                                                //                                         label: attribute.label[this.props.lang],
                                                //                                         values: [{ value: null, name: 'Choose'  ) }, ...attribute.options.map((item) => {
                                                //                                             return {
                                                //                                                 name: item,
                                                //                                                 value: item
                                                //                                             }
                                                //                                         })]
                                                //                                     },

                                                //                                 ]
                                                //                             }
                                                //                  //   }

                                                //                 })
                                                //             },
                                                //         ]
                                                //     }
                                                // }

                                                // ),


                                            ],
                                        },
                                        {
                                            type: 'row',
                                            children: [
                                                {
                                                    type: 'button',
                                                    width: { lg: 4, sm: 6, xs: 6 },
                                                    action: 'submit',
                                                    children: 'Save',
                                                    className: 'form-button',
                                                },

                                            ]
                                        }

                                    ]}
                                ></FormBuilder>
                            </div>

                        </div>

                        :

                        <div>
                            <DeleteModal
                                isOpen={this.state.deleteModal}
                                toggle={() => this.setState({ deleteModal: null })}
                                handler={() => {
                                    this._api('api3', { data: this.state.deleteModal._id }, () => {
                                        this.setState({ groupsForm: {} }, () => { this._onLoad(); this.setState({ deleteModal: null }) })
                                    });
                                }} >
                                {'Delete product'} <strong>{this.state.deleteModal ? this.state.deleteModal.name[this.props.lang] : ''}</strong> ?
                            </DeleteModal>
                            <ListBuilder
                                tabs={true}
                                sort={true}
                                tab1Name={'Products list'}
                                tab2Name={"New products"}
                                tab3Name={"Hidden products"}
                                tab4Name={"Add new product"}
                                tab2Active={this.props[0].location.search.indexOf('_newProduct') !== -1}
                                tab3Active={this.props[0].location.search.indexOf('_hidden') !== -1}
                                onClickTab1={() => {
                                    this.setState({
                                        articlesList: true,
                                        articleForm: false,
                                    })
                                    this.updateMultipleParams([{ name: 'entries', value: "10" }, { name: 'page', value: 0 }, { name: 'filter', value: '' }, { name: '_newProduct', value: false }, { name: '_hidden', value: false }], true);

                                }}
                                onClickTab2={() => {
                                    this.setState({
                                        articleForm: false,
                                        articlesList: true
                                    })
                                    this.updateMultipleParams([{ name: 'entries', value: "10" }, { name: 'page', value: 0 }, { name: 'filter', value: '' }, { name: '_newProduct', value: true }, { name: '_hidden', value: false }], true);
                                }}
                                onClickTab3={() => {
                                    this.setState({
                                        articleForm: false,
                                        articlesList: true
                                    })
                                    this.updateMultipleParams([{ name: 'entries', value: "10" }, { name: 'page', value: 0 }, { name: 'filter', value: '' }, { name: '_newProduct', value: false }, { name: '_hidden', value: true }], true);
                                }}

                                onClickTab4={() => {
                                    this.setState({
                                        articleForm: true,
                                        articlesList: false
                                    })
                                }}
                                lang={this.props.lang}
                                loading={this.state.loading}
                                total={this.state.total}
                                showNumeration={false}
                                fields={this.props[0].location.search.indexOf('_hidden') !== -1 ?
                                    [
                                        { type: 'image', name: 'thumbnailExposed', label: '', multilang: false, allowSort: false },

                                        { type: 'text', name: 'sku', label: 'SKU', multilang: false, allowSort: true },
                                        { type: 'text', name: 'active', label: 'IS ACTIVE', multilang: false, allowSort: true },

                                        { type: 'text', name: 'category', label: 'CATEGORY', multilang: false, allowSort: true },
                                        { type: 'text', name: 'tsCreated', label: 'PUBLISHED', multilang: false, allowSort: true },
                                        { type: 'text', name: 'inStock', label: 'IN STOCK', multilang: false, allowSort: true },
                                        { type: 'text', name: 'onStockTs', label: 'LAST TIME ON STOCK', multilang: false, allowSort: true },
                                        { type: 'text', name: 'isHidden', label: 'IS HIDDEN', multilang: false, allowSort: true },
                                        { type: 'text', name: 'isHiddenForPublic', label: 'IS HIDDEN FOR PUBLIC', multilang: false, allowSort: true },
                                        { type: 'text', name: 'nextStock', label: 'NEXT STOCK', multilang: false, allowSort: true },

                                    ]
                                    : [
                                        { type: 'image', name: 'thumbnailExposed', label: '', multilang: false, allowSort: false },

                                        { type: 'text', name: 'sku', label: 'SKU', multilang: false, allowSort: true },
                                        { type: 'text', name: 'brand', label: 'BRAND', multilang: false, allowSort: true },

                                        { type: 'text', name: 'name', label: 'NAME', multilang: true, allowSort: true },
                                        { type: 'text', name: 'inStock', label: 'IN STOCK', multilang: false, allowSort: true },
                                        { type: 'text', name: 'discount', label: 'SALE', multilang: false, allowSort: true },
                                        { type: 'text', name: 'views', label: 'VIEWS', multilang: false, allowSort: true },
                                    ]}
                                items={this.props[0].location.search.indexOf('_hidden') !== -1 ? this.state.items.map((item) => {
                                    return {
                                        ...item,
                                        active: item.active ? 'YES' : 'NO',
                                        tsCreated: item.tsCreated ? `${moment.unix(item.tsCreated).format('YYYY-MM-DD')}` : 'N/A',
                                        category: item.breadcrumb && item.breadcrumb[0] && item.breadcrumb[0].en && item.breadcrumb[0].en.indexOf('indoor') ?
                                            'INDOOR' :
                                            item.breadcrumb && item.breadcrumb[0] && item.breadcrumb[0].en && item.breadcrumb[0].en.indexOf('outdoor') ?
                                                'OUTDOOR' :
                                                'N/A',
                                        isHiddenForPublic: item.isHiddenForPublic ? 'YES' : 'NO',
                                        isHidden: item.isHidden ? 'YES' : 'NO',
                                        nextStock: item.nextStock && item.nextStock.timestamp ? `${moment.unix(item.nextStock.timestamp).format('YYYY-MM-DD')}\n${item.nextStock.value}` : 'N/A',
                                        onStockTs: item.onStockTs ? `${moment.unix(item.onStockTs).format('YYYY-MM-DD')}` : 'N/A',

                                    }
                                }) : this.state.items}

                                actions={
                                    this.props[0].location.search.indexOf('_hidden') !== -1 ?
                                        [] :
                                        [
                                            {
                                                component: <Isvg src={editIcon} />,
                                                onClick: (item) => this.setState({ articleForm: item, isHidden: item.isHidden })
                                            },
                                            {
                                                component: <Isvg src={garbageIcon} />,
                                                onClick: (item) => this.setState({ deleteModal: item })
                                            },
                                        ]
                                }

                                params={params}
                                sortField={params.sortField}
                                sortType={params.sortType}
                                updateSort={this.updateSort}
                                updateParams={this.updateParams}
                                updateMultipleParams={this.updateMultipleParams}
                            ></ListBuilder>
                        </div>
                }
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={'bg-unset'} >
                    <ModalBody className={'bg-unset'}>
                        <Spinner
                            color="danger"
                            size={'xl'}
                        >
                            Loading...
                        </Spinner>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default Page(Home);

/*

*/
