import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import axios from 'axios';
import Isvg from 'react-inlinesvg';
import image from '../../assets/image.svg';
import { API_ENDPOINT } from '../../constants';


/**
* Component for import files
* @author   Milan Stanojevic
*/
class File extends Component {
    constructor(props) {
        super(props);
        this.selectFile = this.selectFile.bind(this);

        this.state = {

        };
    }

    selectFile(e) {
        this.setState({
            error: null
        })
        let input = e.target;
        if (input.files && input.files[0]) {


            let formData = new FormData();
            formData.append('file', input.files[0]);

            this.setState({ _loading: '0%' })

            axios.post(API_ENDPOINT.replace('testapi', 'api') + this.props.endpoint, formData, {
                headers: {
                    Accept: 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                },
                onUploadProgress: (progressEvent) => {
                    this.setState({ _loading: Math.round((progressEvent.loaded * 100) / progressEvent.total) + '%' })

                }
            }).then((response) => {
                if (response.status !== 200) {
                    this.setState({ error: response.data, _loading: null })
                } else {
                    this.props.onChange(response.data);
                    this.setState({
                        _loading: null
                    })
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status !== 200)
                        this.setState({ error: error.response.data, _loading: null })

                }
            })



        }
    }

    render() {
        return (
            <FormGroup>
                <Label>{this.props.label}</Label>
                <div className="image-upload-wrap">
                    <input type="file" onChange={this.selectFile} />

                    <div>
                        {this.props.value ? <img src={API_ENDPOINT.replace('testapi', 'api') + this.props.value} /> : null}
                        {this.state._loading ?
                            <div className="progress-wrap">
                                <div className="progress"><div style={{ width: this.state._loading }}></div></div>
                                <span>{this.state._loading}</span>
                            </div> : null
                        }
                        <div className="bottom-content">
                            <Isvg src={image} />
                            <p> <span>{'Upload a file'}</span> {'or drag and drop'}</p>
                        </div>

                    </div>
                </div>


            </FormGroup>


        );
    }
}

export default File;
